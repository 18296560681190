import React from "react"
import Slider from "react-slick"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const getTestimonials = graphql`
  query {
    allContentfulTestimonial {
      nodes {
        testimonial {
          testimonial
        }
      }
    }
    fileName: file(relativePath: { eq: "banner-testimonials.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Testimonials = () => {
  const data = useStaticQuery(getTestimonials)

  var settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <BackgroundImage
      className="c-testimonials"
      Tag="div"
      fluid={data.fileName.childImageSharp.fluid}
      backgroundColor={`#261d46`}
    >
      <div className="container">
        <div className="row">
          <div className="c-testimonials__slider">
            <h2 className="c-testimonials__header heading-large">
              Testimonials
            </h2>
            <Slider {...settings}>
              {data.allContentfulTestimonial.nodes.map((item, index) => {
                return (
                  <div key={index}>
                    <blockquote className="c-testimonials__blockquote">
                      <p className="c-testimonials__quote para-large">
                        {item.testimonial.testimonial}
                      </p>
                    </blockquote>
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default Testimonials
