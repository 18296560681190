import React, { useEffect } from "react"
import locomotiveScroll from "locomotive-scroll"
import Seo from "./Seo"
import Footer from "./Footer"
import Navigation from "./Navigation"
import "../styles/site.scss"

const Layout = ({ children }) => {
  const scrollRef = React.createRef()

  useEffect(() => {
    new locomotiveScroll({
      el: scrollRef.current,
      smooth: false,
    })
  })

  return (
    <div className="scroll" ref={scrollRef}>
      <Seo title="Step 2 Wellbeing" />
      <a href="#main-content" className="u-visually-hide">
        Skip to content
      </a>
      <Navigation />
      {children}
      <Footer />
    </div>
  )
}

export default Layout
