import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const Banner = ({ image, title, ishome }) => {
  return (
    <BackgroundImage
      className="c-banner"
      Tag="div"
      fluid={image}
      backgroundColor={`#ffffff`}
    >
      <div className="container">
        <div className="row">
          <div className="c-banner__content">
            <h1 className="c-banner__header">{title}</h1>
            {ishome ? (
              <div>
                <p className="c-banner__intro">
                  At Step2Wellbeing we combine passion, experience and knowledge
                  to help you maintain optimal health, accelerate recovery and
                  improve your performance.
                </p>
                <Link to="/book" className="c-btn c-btn--secondary">
                  Book Now
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default Banner
