import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

const getFooter = graphql`
  query {
    allFile(filter: {relativePath: {eq: "logo.png"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`

const Footer = () => {
  const data = useStaticQuery(getFooter)
  return (
    <div className="c-footer">
      <div className="container">
        <div className="row">
          <div className="c-footer__logo">
            <img src={data.allFile.edges[0].node.publicURL} alt="Step 2 Wellbeing" />
          </div>
          <nav className="c-footer__nav">
            <ul className="c-footer__nav-items">
              <li className="c-footer__nav-item">
                <Link to="/" className="c-footer__nav-link">
                  Home
                </Link>
              </li>
              <li className="c-footer__nav-item">
                <Link to="/about" className="c-footer__nav-link">
                  About
                </Link>
              </li>
              <li className="c-footer__nav-item">
                <Link to="/treatments" className="c-footer__nav-link">
                  Treatments
                </Link>
              </li>
              <li className="c-footer__nav-item">
                <Link to="/book" className="c-footer__nav-link">
                  Book
                </Link>
              </li>
              <li className="c-footer__nav-item">
                <Link to="/terms-and-condtions" className="c-footer__nav-link">
                  Terms and conditions
                </Link>
              </li>
              <li className="c-footer__nav-item">
                <Link to="/privacy" className="c-footer__nav-link">
                  Privacy
                </Link>
              </li>
            </ul>
          </nav>
          <div className="c-footer__legal">
            &copy;{new Date().getFullYear()} All Rights Reserved <br />
            Registered Company No.: 123456789
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
