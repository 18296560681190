import React, { useState } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

const getNav = graphql`
  query {
    allFile(filter: { relativePath: { eq: "logo.png" } }) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`

const Navigation = () => {
  const data = useStaticQuery(getNav)
  const [toggleNavigation, settoggleNavigation] = useState("")

  const burgerHandler = (e) => {
    e.preventDefault()
    settoggleNavigation(toggleNavigation === "" ? "is-active" : "")
  }

  return (
    <nav
      className={toggleNavigation === "" ? "c-navbar" : "c-navbar is-active"}
    >
      <div className="container">
        <div className="row">
          <div className="c-navbar__wrapper">
            <a href="/" className="c-navbar__brand-mobile">
              <img
                src={data.allFile.edges[0].node.publicURL}
                alt="Step 2 Wellbeing"
              />
            </a>
            <div
              className={
                toggleNavigation === ""
                  ? "c-navbar__links"
                  : `c-navbar__links ${toggleNavigation}`
              }
              id="js-navbar-links"
            >
              <ul className="c-navbar__list">
                <li className="c-navbar__item">
                  <Link
                    to="/"
                    className="c-navbar__link is-active"
                    aria-current="page"
                  >
                    Home
                  </Link>
                </li>
                <li className="c-navbar__item">
                  <Link to="/about" className="c-navbar__link">
                    About
                  </Link>
                </li>
                <li className="c-navbar__item">
                  <Link to="/blog" className="c-navbar__link">
                    Blog
                  </Link>
                </li>
                <li className="c-navbar__item">
                  <Link to="/treatments" className="c-navbar__link">
                    Treatments
                  </Link>
                </li>
                <li className="c-navbar__item">
                  <Link to="/book" className="c-btn c-btn--tertiary">
                    Book Now
                  </Link>
                </li>
              </ul>
            </div>
            <button
              className={
                toggleNavigation === ""
                  ? "c-navbar__menu"
                  : `c-navbar__menu ${toggleNavigation}`
              }
              onClick={(e) => burgerHandler(e)}
              aria-controls="js-navbar-links"
              type="button"
            >
              <i className="icon-burger" aria-hidden="true"></i>
              <span className="u-visually-hide">Menu</span>
            </button>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navigation
